import React from "react"
import Formulario from "../Contact.jsx"
import Breadcrumbs from "../breadcrumbs"
import BannerTop from "../../v1/Global/Modules/MediaPush/BannerTop.jsx"
const Main = () => {
  return (
    <section className="main-contacto pb-0 d-flex justify-content-center align-items-center flex-column">
      <div className="container-fluid">
        <div className="d-flex justify-content-center mb-4 mb-lg-0 w-100">
          <Breadcrumbs
            props={[
              { name: "Bullrich Campos", route: "/", location: "" },
              { name: "Contacto", route: "", location: "" },
            ]}
          />
        </div>
        <Formulario
          bigTitle={true}
          from={"contact"}
          title="Complete el formulario para solicitar <br> una entrevista."
        />
      </div>
    </section>
  )
}

export default Main

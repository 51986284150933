import * as React from "react"
import Layout from "../../components/v2/layout"
import Seo from "../../components/seo"
import Main from '../../components/v2/Contacto/main.jsx'
import BannerTop from "../../components/v1/Global/Modules/MediaPush/BannerTop"

//Components

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
      
  return(
  <Layout>
    <Seo title="Creciendo junto al campo desde 1867" description="Más de 150 años de experiencia en el sector rural. Conozca los campos más destacados de la Argentina, servicio de tasación satelital, divisiones y consultoría."/>
    <Main/>
  </Layout>
)}

export default IndexPage
